import { createSelector } from 'reselect'
import type { Shared } from 'types/Shared'
import 'types/Shared'
import { getShared } from './helpers'
export const getModal = createSelector(
  [getShared],
  (shared: Shared): Record<string, any> => shared.modal || {}
)
export const getModalIsVisible = createSelector(
  [getModal],
  (modal: Record<string, any>): boolean => modal.isVisible || false
)
export const getModalName = createSelector(
  [getModal],
  (modal: Record<string, any>): string => modal.name || ''
)

export const getHasModalName = createSelector(
  [getModal],
  (modal: Record<string, any>) => !!modal.name
)

export const getIsCurrentModalAvailable = (name: string = '') =>
  createSelector([getModalName], (modalName): boolean => !!(modalName && modalName === name))
