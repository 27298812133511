import React from 'react'
import { useStore } from 'react-redux'
import { parseSku } from 'lib/product'
import { addStoreLocaleByCookie } from 'components/GeoLocation/helpers'
import { convertFromCentsToDollarsString } from 'components/FormatPrice/helpers'
import { ActionItem, CartIcon as NewCartIcon } from './styles'
const PRODUCT_TYPES = {
  [`1`]: 'original',
  [`2`]: 'print',
  [`3`]: 'print',
  [`4`]: 'original',
}
export const gaAddToCartWhitelist = (data: Record<string, any>) => {
  const { artistName, category, price, sku, title, listId, dataPosition, artworkId } = data

  // You can not add a PRODUCT with NO SKU so return empty object
  if (!sku) {
    return {}
  }

  const { productTypeId } = parseSku(sku) // [configurationId, userId, artworkId, productTypeId]

  return {
    event: `add_to_cart`,
    ecommerce: {
      currency: 'USD',
      value: convertFromCentsToDollarsString(price), // artwork price
      items: [
        {
          item_id: sku || '',
          item_name: title || '',
          affiliation: 'Online Store',
          index: dataPosition || 0,
          item_brand: artistName || '',
          item_art_id: artworkId || '',
          item_category: category || '',
          item_variant: PRODUCT_TYPES[productTypeId] || 'original',
          item_list_id: listId || '', // product list from where the product was added to cart
          price: convertFromCentsToDollarsString(price),
          quantity: 1,
        },
      ],
    },
  }
}
type Props = {
  artworkId: number
  eventType: string
  productEvent: Record<string, any>
  // New GA4 data - parsed artwork into product data via `gaAddToCartWhitelist`
  sku: string
  status: string
}

const AddToCartClick = async (params: Record<string, any>): Promise<void> => {
  const { artworkId, eventType, productEvent, sku, store } = params

  if (!sku) {
    return
  }

  const { postCartItem } = await import('apis/cart')
  const { algoliaEventsApi } = await import('apis/algolia')
  const { getIndice } = await import('selectors/algolia')
  const { getAlgoliaUserToken } = await import('selectors/user/cookie')
  const { message, success } = await postCartItem(sku)

  if (!success) {
    const { Actions: FlashMessageActions } = await import('actions/shared/flashMessage')
    store.dispatch(
      FlashMessageActions.ADD({
        messages: [message],
        type: 'error',
      })
    )
    return
  }

  // Algolia
  const state = store.getState()
  const searchIndice = getIndice(state)
  const userToken = getAlgoliaUserToken(state)
  // TODO - Look into dropping or correcting this logic
  // Need more information from product about algolia insights
  algoliaEventsApi({
    events: [
      {
        index: searchIndice,
        userToken,
        eventName: 'add to cart',
        eventType: 'conversion',
        objectIDs: [String(artworkId)],
      },
    ],
  })
  const checkoutLocation = addStoreLocaleByCookie(`/checkout`)

  // GTM/Datalayer is DISABLED
  if (!window.dataLayerEnabled) {
    window.location = checkoutLocation
    return
  }

  // GA4 Event
  window.dataLayer.push({ event: `addToCart`, prompt: eventType })
  // Clear the previous ecommerce object
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    ...productEvent,

    eventCallback() {
      location.assign(checkoutLocation)
    },

    eventTimeout: 2000,
  })
}

const AddToCartAction = (
  props: Props
): React.ReactElement<React.ComponentProps<any>, any> | null => {
  const { sku, status } = props
  const store = useStore()

  if (!sku || status !== 'avail') {
    return null
  }

  return (
    <ActionItem
      data-action='cart'
      data-type='action'
      title='Add to Cart'
      onClick={async (ev: React.SyntheticEvent<HTMLLinkElement>) => {
        ev.preventDefault()
        AddToCartClick({ ...props, store })
      }}
    >
      <NewCartIcon />
    </ActionItem>
  )
}

export default AddToCartAction