import React, { useState, useEffect, useRef } from 'react'
import SALink from 'components/SALink'
import Favorite from 'components/Artworks/Actions/Favorites/Action'
import AddToCart, { gaAddToCartWhitelist } from 'components/Artworks/Actions/AddToCart'
import useCarousel from 'hooks/carouselTimer' // Basic Position Logic

import {
  convertDefaultToLocaleCurrency,
  formatPrice,
  convertFromCentsToDollars,
} from 'components/FormatPrice/helpers'
import type { ConnectorProps as FavoritesProps } from '../connectors/Favorites'
import FavoritesConnector from '../connectors/Favorites'
import { NextIcon, PrevIcon } from './styles'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

const ITEM_WIDTH = 156
const MARGIN = 8
type SectionProps = {
  containerRef: Record<string, any>
  contentRef: Record<string, any>
}

const getElementDimensions = (ref: Record<string, any>): Record<string, any> => {
  return ref?.current?.getBoundingClientRect?.()?.toJSON?.() || {}
}

const getChildrenLength = (ref: Record<string, any>): number => {
  if (ref.current) {
    const { childElementCount } = ref.current // children => HTMLCollection (OBJECT)

    return childElementCount
  }

  return 0
}

// Resize - Find Children Width / ItemIndex
const getSectionsArray = ({ containerRef, contentRef }: SectionProps): Record<string, any> => {
  const container = getElementDimensions(containerRef)
  const listItems = getChildrenLength(contentRef)
  // We want to keep how many items fit in view, before we have to create a new sections
  const limit = listItems - 1 // how many items inside VIEW

  const sections = [0] // keep track of how many sections are available

  let count = 0 // how many items have we done ( incremented, put in sections )

  let totalSize = 0

  while (count <= limit) {
    let size = 0

    while (size < container.width) {
      // We want to make sure we don't fall into a INFINITE LOOP
      // Make sure we have already incremented added one item to SIZE
      // Problem: if the item is bigger then container, we would never increment
      if (size && size + ITEM_WIDTH > container.width) {
        // We have met Width of Container, Escape early and start new section
        break
      }

      size += ITEM_WIDTH + MARGIN
      count++

      // Make sure we END the last section
      if (count > limit) {
        break
      }
    }

    if (count > limit) {
      // We need to overwrite the last value
      // We want to show last item FLUSH against the right side
      totalSize -= container.width + MARGIN - size
      sections[sections.length - 1] = totalSize
    } else {
      totalSize += size
      sections.push(totalSize)
    }
  }

  return {
    sections,
    containerWidth: container.width,
  }
}

const Component = (props: FavoritesProps): React.ReactElement<React.ComponentProps<any>, any> => {
  const { accountType, favorites, selectedCurrency, userID } = props
  const containerRef: Record<string, any> = useRef()
  const contentRef: Record<string, any> = useRef()
  const scrollRef: Record<string, any> = useRef()
  const [sections, handleSections] = useState([])
  const { nextPosition, position, prevPosition, setItems, setPosition } = useCarousel({
    items: 0,
    enableTimer: false,
    timerDuration: 3000,
  })
  // We need to check USER type for correct list id - can be Curator/Artist
  const userAccountType = accountType.toLowerCase()
  const listId = `${userAccountType}-profile-favorites`
  useEffect(() => {
    const { sections: sectionArray } = getSectionsArray({
      containerRef,
      contentRef,
    })

    // Reset Mobile Scroller
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = 0
    }

    handleSections(sectionArray)
    setItems(sectionArray.length)
    setPosition(0)
  }, [favorites])

  const handlePrevPosition = (ev: React.SyntheticEvent<HTMLButtonElement>) => {
    ev.preventDefault()
    prevPosition()
  }

  const handleNextPosition = (ev: React.SyntheticEvent<HTMLButtonElement>) => {
    ev.preventDefault()
    nextPosition()
  }

  const translateX = sections[position] || 0
  return (
    <div data-type='favorites-section'>
      <SATypography data-type='main-title' variant={SATypographyVariant.H4}>
        Curator Favorites
      </SATypography>
      {favorites.length ? (
        <div ref={containerRef}>
          <div data-type='controls'>
            {position > 0 && (
              <button
                data-action='previous'
                type='button'
                onClick={handlePrevPosition}
                onKeyPress={handlePrevPosition}
              >
                <PrevIcon />
              </button>
            )}

            {position < sections.length - 1 && (
              <button
                data-action='next'
                type='button'
                onClick={handleNextPosition}
                onKeyPress={handleNextPosition}
              >
                <NextIcon />
              </button>
            )}
          </div>

          <div ref={scrollRef} data-type='carousel'>
            <ul
              ref={contentRef}
              style={{
                transform: `translateX(-${translateX}px)`,
              }}
            >
              <>
                {favorites.map((favorite, key) => {
                  const {
                    artistID,
                    artistName,
                    artworkID,
                    artworkImage,
                    category,
                    listPrice,
                    originalStatus,
                    pdpUrl,
                    productSku,
                    title,
                  } = favorite
                  const dollarToCents = convertFromCentsToDollars(parseInt(listPrice, 10))
                  const localePrice = formatPrice(
                    convertDefaultToLocaleCurrency(dollarToCents, selectedCurrency),
                    selectedCurrency
                  )
                  return (
                    <li key={key}>
                      <div data-section='artwork-info'>
                        <SALink href={pdpUrl} title={`${title} ${category}`}>
                          <img src={artworkImage} />
                        </SALink>
                        <div data-type='actions'>
                          <Favorite artworkId={Number(artworkID)} dataStyle='profile' />
                          {originalStatus === 'for-sale' && (
                            <AddToCart
                              productEvent={gaAddToCartWhitelist({
                                artistName,
                                category,
                                listId,
                                price: listPrice,
                                sku: productSku,
                                title,
                                artworkId: artworkID,
                              })}
                              artworkId={Number(artworkID)}
                              sku={productSku}
                              status='avail'
                              eventType={listId}
                            />
                          )}
                        </div>
                        <SALink
                          href={pdpUrl}
                          title={`${title} ${category}`}
                          data-type='artwork-title'
                        >
                          {title}
                        </SALink>
                        <SALink href={`/account/profile/${artistID}`} data-type='artist-name'>
                          {artistName}
                        </SALink>
                        {originalStatus === 'for-sale' && <p data-type='price'>{localePrice}</p>}
                      </div>
                    </li>
                  )
                })}
              </>
            </ul>
          </div>
          <SALink href={`/account/favorite/${userID}`} data-type='view-all'>
            View All Favorites
          </SALink>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default FavoritesConnector(Component)